import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { RichText, RichTextBlock } from 'prismic-reactjs';

import { graphql } from 'gatsby';

import LayoutFrame from '../components/layout/LayoutFrame';
import { Typography } from '@nelnet/unifi-components-react';
import Hero from '../components/layout/Hero';
import CalloutGroup from '../components/layout/CalloutGroup';
import ContentSummary from '../components/layout/ContentSummary';
import DescriptionList from '../components/layout/DescriptionList';

interface ICapabilitiesProps {
  data: {
    prismic: {
      allCapabilitiess: {
        edges: [
          {
            node: {
              _meta: {
                uid: string;
              };
              image: {
                url: string;
                alt: string;
              };
              title: RichTextBlock[];
              content_description: RichTextBlock[];
              primary_page_color: string;
              page_font_color: string;
              body: [
                {
                  fields: {
                    expand_list_item: string;
                    expanded_text: {
                      text: string;
                      spans: {
                        data: {
                          url: string;
                        };
                      };
                    };
                  };
                },
                {
                  primary: {
                    category: string;
                    category_summary: string;
                    list_group_header: string;
                    list_header_image: {
                      url: string;
                      alt: string;
                    };
                  };
                },
              ];
              section_title: RichTextBlock[];
              section_summary: RichTextBlock[];
            };
          },
        ];
      };
      allCapabilits: {
        edges: [
          {
            node: {
              _meta: {
                uid: string;
              };
              header_content: string;
              title: string;
            };
          },
        ];
      };
      allFooters: {
        edges: [
          {
            node: {
              title: RichTextBlock[];
              subtitle: RichTextBlock[];
              button_text: RichTextBlock[];
              button_style: 'text' | 'outlined' | 'contained';
              button_link: {
                text: string;
              };
            };
          },
        ];
      };
    };
  };
}

const Capabilities: React.FunctionComponent<ICapabilitiesProps> = ({ data }) => {
  // Required check for no data being returned
  const doc = data.prismic.allCapabilitiess.edges.slice(0, 1).pop();
  const footerDoc = data.prismic.allFooters.edges.slice(0, 1).pop();

  const uid = doc.node._meta.uid;
  const slug = uid === 'home' ? '/' : `/${uid}/`;
  const title = doc.node.title;

  if (!doc) {
    return (
      <LayoutFrame currPath={slug}>
        <Helmet>
          <title>{RichText.asText(title)}</title>
        </Helmet>
        <Typography variant="h2" margin={{ bottom: 3 }}>
          {RichText.asText(title)}
        </Typography>
      </LayoutFrame>
    );
  }

  const { content_description, section_title, section_summary } = doc.node;
  const icon_url = doc.node.image.url;
  const icon_alt = doc.node.image.alt;
  const page_font_color = doc.node.page_font_color.slice(-7);
  const page_color = doc.node.primary_page_color.slice(-7);

  const content_fields = doc.node.body;

  const list_items = data.prismic.allCapabilits.edges;

  const { button_text, button_style } = footerDoc.node;
  const footer_title = footerDoc.node.title;
  const footer_subtitle = footerDoc.node.subtitle;
  const button_link = footerDoc.node.button_link[0].text;

  return (
    <LayoutFrame currPath={slug}>
      <Helmet>
        <title>{RichText.asText(title)}</title>
      </Helmet>
      <Hero
        icon_url={icon_url}
        icon_alt={icon_alt}
        title={title}
        subtitle={content_description}
        page_color={page_color}
        page_font_color={page_font_color}
      ></Hero>
      <ContentSummary content_fields={content_fields} parent_slug={uid}></ContentSummary>
      <DescriptionList
        title={section_title}
        summary={section_summary}
        list_items={list_items}
        parent_slug={uid}
      ></DescriptionList>
      <CalloutGroup
        title={footer_title}
        subtitle={footer_subtitle}
        button_text={button_text}
        button_style={button_style}
        button_url={button_link}
      ></CalloutGroup>
    </LayoutFrame>
  );
};

export default Capabilities;

export const query = graphql`
  query CapabilitiesQuery {
    prismic {
      allCapabilitiess {
        edges {
          node {
            _meta {
              uid
            }
            image
            title
            content_description
            primary_page_color
            page_font_color
            body {
              ... on PRISMIC_CapabilitiesBodyContent_list {
                fields {
                  expand_list_item
                  expanded_text
                }
                primary {
                  category
                  category_summary
                  list_group_header
                  list_header_image
                }
              }
            }
            section_title
            section_summary
          }
        }
      }
      allCapabilits(sortBy: title_ASC, first: 50) {
        edges {
          node {
            _meta {
              uid
            }
            header_content
            title
          }
        }
      }
      allFooters {
        edges {
          node {
            title
            subtitle
            button_text
            button_style
            button_link
          }
        }
      }
    }
  }
`;
